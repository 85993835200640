import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { changeFcUserStatus } from "../../redux/actions/fcUsers";
import Loader from "../../components/Loader/Loader";
import { readeQr } from "../../redux/actions/fc";
import { readeEventActionQr, readeEventQr } from "../../redux/actions/events";

const QRreaderPageNew = () => {
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentId = useParams().id;

  let ready = false;

  let location = window.location.pathname;



  
  const initFunc = () => {
    if(location.includes("event")){
      window.location = 'https://sportforall.gov.ua/vstanovit-dodatok/';
    }else{
      if (auth && auth.isLoggedIn) {
        let data = {}
        data.id=currentId;
        if (location.includes("club")) {
          dispatch(readeQr(data)).then(res=>{
            navigate(`/fc/${res.clubId}/users`)
          })
          .catch((e) => {
            navigate(`/start`);
          });
        }
        else if(location.includes("route-point")) {
          dispatch(readeEventQr(data)).then(res=>{
            navigate(`/fc-events/0/${res.sportEventId}/itinerary`)
          })
          .catch((e) => {
            navigate(`/start`);
          });
        }else if(location.includes("event")){
          dispatch(readeEventActionQr(data)).then(res=>{
            if(!res.sportEventId){
              navigate(`/fc-events/0/${currentId}/about`)
            }else{
              navigate(`/fc-events/0/${res.sportEventId}/about`)
            }
          })
          .catch((e) => {
            navigate(`/start`);
          });
        }
        else{
          console.log(sessionStorage.getItem("link"));
          navigate(sessionStorage.getItem("link"))
        }
      } else {
        sessionStorage.setItem("qr", location);
        navigate("/login");
      }
    }
    
    
  };

  useEffect(() => {
    if (!ready) {
      initFunc();
    }
  }, [ready]);

  return (
    <>
      <Loader></Loader>
    </>
  );
};

export default QRreaderPageNew;
